
import { defineComponent, onMounted, ref } from "vue";
import DreamLyfList from "@/components/dreamlyf/DreamLyfList.vue";

export default defineComponent({
  name: "dreamlyf",
  components: {
    DreamLyfList
  },
  setup() {
    const tabIndex = ref(0);

   

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("dreamlyfTab") || "0");
    });

  }
});
