
import { defineComponent } from "vue";
import {getDreamLyfList}  from "@/api/ovi_api";


export default defineComponent({
  name: "ovicoveragetransferlist", 
  methods: {
    async loadDreamLyfList(){ 
      this.loading = true;
      const response = await getDreamLyfList();
       this.list = response.data.pageList; 
       this.loading = false;
    }
  },
  mounted(){
      this.loadDreamLyfList();
  },
  data(){
      return { 
          list : [],
          loading: true,
      }
  }, 
  components: {
  },
  props: {
    widgetClasses: String
  }
});
